import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../globals/seo';
import PageContent from "../components/PageContent";


class HomePage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const links = this.props.data.allLinksYaml.edges;
    
    return (
      <>
        <SEO />
        <PageContent links={links} />
      </>
    );
  }
}

export default HomePage;

export const thumbsQuery = graphql`
  query {
    allLinksYaml {
      totalCount
      edges {
        node {
          id
          text
          description
          url
        }
      }
    }
  }
`;