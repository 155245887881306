import React, { Component } from "react";
import PropTypes from "prop-types";

import logoImg from "../images/large-logo.svg";

class PageContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { links } = this.props;
    // console.log(this.props);
  
    return (
      <>
        <ViewportLogo />
        <article className="site-links-container">
          {links.map(( { node } ) =>
              <Link
                key={node.id}
                data={ node }
              />
          )}
        </article>
      </>
    );
  }
}

function Link ( { data } ) {
  const { text, description, url } = data;
  return (
    <div className="site-link-item">
      <h3 className="site-link-title">
        <a target='_blank' rel='noopener noreferrer' href={url}>{text}</a>
      </h3>
      <p className="site-link-description">{ description }</p>
    </div>
  );
}

function ViewportLogo () {
  return (
    <div className="logo-container">
      <a href="https://elwyn.co" target='_blank' rel='noopener noreferrer'>
        <img className="logo" src={logoImg} alt="Elwyn.co" />
      </a>
    </div>
  )
}

export default PageContent;

PageContent.propTypes = {
  links: PropTypes.array,
};

Link.propTypes = {
  data: PropTypes.object
};

